import { withThemeCreator, styled } from "@material-ui/styles"
const styles = theme =>({
    container:{
      height: "auto",
      // paddingLeft: "6%",
      // paddingRight: "6%",
      paddingBottom: "60px",
      '& .slick-dots li':{
        margin:'0 2px',
        height:'6px'
      },
      '& .slick-dots li button:before':{
        fontSize:'0px',
        background: '#0096e2',
        borderRadius: '20px',
        height:'6px',
      }
    },
    sliderconfig:{
      '& .slick-prev, & .slick-next':{
        top:'-40px',
        right:'0'
      },
      '& .slick-prev':{
        right: '32px',
        left: 'inherit'
      },
      '& .slick-prev:before, & .slick-next:before':{
        fontSize:'32px',
        color:'#0096e2'
      },
      '& .slick-prev:before':{
        // content:'\1438',
      },
      '& .slick-next:before':{
        // content:'\1433',
      }
    },
    title: {
      textAlign: "center",
      fontFamily: 'VodafoneRg-Regular',
      fontSize: "40px",
      paddingTop: "32px",
      marginTop: "0",
      marginBottom: "16px"
    },
    description: {
      fontSize: "24px",
      fontFamily: 'VodafoneLt-Regular',
      textAlign: "center",
      marginTop: "16px"
    },
    flex_and_center: {
      display: "flex",
      justifyContent: "center",
      
    },
  })

export default styles
