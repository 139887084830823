/* eslint-disable  prefer-template */
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Link,NavLink } from 'react-router-dom'


const ButtonEleDropdown = ({option,domains,onHandleInstanceValue,whiteListDomainLoading})=>{
    const domainNameId = domains.filter(domain => {
           return domain.id === option.path
       });
       const domainName = domainNameId.length > 0 ? domainNameId[0].id : null
    // console.log("🚀 ~ domainNameId ~ domainNameId:", option.path,domainNameId[0]?.id, domainName)
       return(
           <button 
               type='button'
               disabled={domainName || whiteListDomainLoading} 
               className='btn-transparent' 
               onClick={onHandleInstanceValue} 
               value={option.path}> 
               {/* <span><img src={VoisIcon} alt='voisincon' /></span>  */}
               {option.title}
           </button>
       )
   }
   


const DropdownEle = ({submenu,closeSidebarMenu,domains,onHandleInstanceValue,whiteListDomainLoading})=>{    
    const [isOpen, setIsOpen] = useState(false);
    //close outside click menu
    const ref = useRef()
    useEffect(() => {
        const handler = (event) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [isOpen]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        if(isOpen){
            setIsOpen(false);
        }
    };

    return(
        <div role="button" tabIndex="0" className="menu-dropdown" onClick={closeDropdown} ref={ref}>
            <button type='button' className="dropdown-toggle" onClick={handleToggle} >
                {submenu.title}
                <span className={clsx('icon-down')} />
            </button>
            {isOpen && (
                <ul className='dropdown-menu dropdown'>
                    {/* {console.log(';;;',submenu)} */}
                    {
                        submenu.sub.map((option) => (
                            <li key={option.title}  >
                              {  
                                submenu.buttonEle === true ? 
                                    <ButtonEleDropdown 
                                        domains={domains}  
                                        option={option} 
                                        onHandleInstanceValue={onHandleInstanceValue}
                                        whiteListDomainLoading={whiteListDomainLoading}
                                    />

                                 : option && option.adminRole !== false &&  <NavLink to={option.path} onClick={closeSidebarMenu} className="menuItem">{option.title}</NavLink>
                              }
                            </li>
                        ))
                    }
                </ul>
            )}
        </div>
    )
}


const MenuItem = ({navbarItem,onHandleInstanceValue,whiteListDomainLoading,domains,closeSidebarMenu}) => {
      

  return (
    <>
        {
              navbarItem.map((item) => (
                  item.sub ?
                      <DropdownEle key={item.title} 
                        submenu={item} 
                        domains={domains} 
                        onHandleInstanceValue={onHandleInstanceValue}
                        whiteListDomainLoading={whiteListDomainLoading}    
                    />
                      : <NavLink key={item.title} className={isActive =>
                          "menuItem" + (!isActive ? " unselected " : "")
                      } to={item.path} onClick={closeSidebarMenu} >{item.title}</NavLink>
              ))
        }
    
    </>
  )
}

export default MenuItem