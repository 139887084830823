import { withThemeCreator, styled } from "@material-ui/styles"
const styles = theme =>({
    container:{
      height: "auto",
      // paddingLeft: "6%",
      // paddingRight: "6%",
      paddingBottom: "60px",
      '& .slick-dots li':{
        margin:'0 2px',
        height:'6px'
      },
      '& .slick-dots li button:before':{
        fontSize:'0px',
        background: '#0096e2',
        borderRadius: '20px',
        height:'6px',
      }
    },
    
  })

export default styles
